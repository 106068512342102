import { Link } from "react-router-dom";
import { brandName, generalInfo, getInTouch, menu } from "../../utils/config";
import { logo } from "../../utils/Images";

const Footer = () => {
  return (
    <>
      <footer className="bg-footer py-5 d-none d-md-block">
        <div className="container">
          <div className="row text-white">
            <div className="col-md-4 col-12">
              <div><img src={logo} alt="" className="img-fluid footer-logo" style={{ height: "180px" }} /></div>
            </div>
            <div className="col-md-2 col-6">
              <h6 className="text-uppercase mb-4 fw-bold">Menu</h6>
              <ul className="list-unstyled d-grid gap-2 text-decoration-none">
                {
                  menu.map(item => (
                    <li key={item.title}><Link className="text-decoration-none text-white-50" to={item.url}>{item.title}</Link></li>
                  ))
                }
              </ul>
            </div>
            <div className="col-md-2 col-6">
              <h6 className="text-uppercase mb-4 fw-bold">Address</h6>
              <ul className="list-unstyled d-grid gap-2">
                <li>{generalInfo.address}</li>
              </ul>
            </div>
            <div className="col-md-2 col-6">
              <h6 className="text-uppercase mb-4 fw-bold">Firm</h6>
              <ul className="list-unstyled d-grid gap-2">
                <li>Ismail Umar & Co.</li>
                <li>Ismail Umar Sales</li>
              </ul>
            </div>
            <div className="col-md-2 col-6">
              <h6 className="text-uppercase mb-4 fw-bold">get in touch</h6>
              <ul className="list-unstyled d-grid gap-2">
                {
                  getInTouch.map(item => (
                    <li key={item.title}>
                      <Link
                        className="text-decoration-none text-white-50"
                        to={
                          item.type === 'mail'
                            ? `mailto:${item.url}`
                            : item.type === 'phone'
                              ? `tel:${item.url}`
                              : item.type === 'url'
                                ? item.url
                                : '#'
                        }
                        target={item.type === 'url' ? '_blank' : undefined}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))
                }

              </ul>
            </div>
          </div>
          <hr className="text-white" />
          <div className="text-white" style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="text-white-50">Copyright © 2024-{new Date().getFullYear() + 1} {brandName}. All Right Reserved.</div>
            <div className="fw-bold">Powered By: <Link className="text-decoration-none text-white-50" to="https://www.inshatech.com" target="_blank"> Insha Technologies</Link></div>

          </div>
        </div>
      </footer>
    </>
  )
};

export default Footer
