import Partners from "../components/common/Partners";
import { team } from "../utils/config";
import { mission, vision } from "../utils/Images";

const About = () => {
  return (
    <>
      <header className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-8">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">Welcome to our official website</h1>
                <p className="lead fw-normal text-muted mb-4">Ismail Umar And Company is also know as Ismail Umar & Co.(IUC) is known to satisfactorily cater to the demands of its customer base. The business came into existence in 1952 and has, since then, been a known name in its field. It stands located at Shop No 37 & 38, Old Mondha, Opp. Nasheman Hotel Parbhani -431401.</p>
                <a className="btn btn-success rounded-pill py-3 px-4 btn-lg" href="#scroll-target"> <span className="px-3"> Read our story </span> </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="py-5 bg-white" id="scroll-target">
        <div className="container my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6"><img className="img-fluid rounded mb-5 mb-lg-0" src={vision} alt="..." /></div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">Ismail Umar And Company</h2>
              <p className="lead fw-normal text-muted mb-0">Ismail Umar And Company proudly stands as a trusted supplier of groceries, meeting the varied needs of retailers and businesses in the bustling Market Yard. Ismail Umar & Co.(IUC) has a rich history of year. This esteemed establishment is synonymous with quality, dependability, and an extensive assortment of grocery essentials.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last"><img className="img-fluid rounded mb-5 mb-lg-0" src={mission} alt="..." /></div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">Ismail Umar Sales</h2>
              <p className="lead fw-normal text-muted mb-0">Ismail Umar Sales transcends the role of a mere grocery wholesaler, emerging as a reliable partner for retailers seeking quality products and efficient services. With its locality, decades of experience, commitment to customer satisfaction, and diverse offerings, the wholesaler is a leader in providing a seamless and efficient experience for businesses in the grocery retail sector. As the grocery landscape evolves, Ismail Umar Sales remains a trusted and customer-focused wholesale partner, contributing to the success of retailers and businesses in Pune and beyond.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-white">
        <div className="container mt-5">
          <div className="text-center">
            <h2 className="fw-bolder">Our team</h2>
            <p className="lead fw-normal text-muted mb-5">Dedicated to quality and your success</p>
          </div>
          <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
            {
              team.map(item => (
                <div className="col mb-5 mb-5 mb-xl-0" key={item.name}>
                  <div className="text-center">
                    <img className="img-fluid rounded-circle mb-4 px-4" src={item.image} alt="..." />
                    <h5 className="fw-bolder">{item.name}</h5>
                    <div className="text-muted">{item.designation}</div>
                    <div className="text-muted">{item.mobile}</div>
                    <div className="text-muted">{item.email}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
      <Partners/>
    </>
  )
};

export default About
