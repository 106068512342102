import IframeResizer from "@iframe-resizer/react";
import { Link } from "react-router-dom";
import { generalInfo } from "../utils/config";
import ContactForm from "../components/ContactForm";


const Contact = () => {

  return (
    <>
      <section className="pt-5 bg-success">
        <div className="container py-5 px-5 text-center">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="text-white display-5 mb-2 fw-bold">Contact us</h1>
              <p className="lead text-white-50 m-0">Have questions? We're here to help!</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 text-center">
        <div className="container">
          <div className="row gx-5 row-cols-2 row-cols-lg-3 py-5">
            <div className="col">
              <div className="feature bg-warning bg-gradient text-white rounded-pill mb-3"><i className="icofont-pin"></i></div>
              <div className="h5 mb-2 fw-bold text-black">Address</div>
              <p className="text-muted mb-0">{generalInfo.address}</p>
            </div>
            <div className="col">
              <div className="feature bg-warning bg-gradient text-white rounded-pill mb-3"><i className="icofont-phone"></i></div>
              <div className="h5 fw-bold text-black">Contact Us</div>
              <p className="text-muted mb-0">Mobile: <Link to={`tel:${generalInfo.mobile}`} className="text-muted mb-0" >{generalInfo.mobile}</Link></p>
              <p className="text-muted mb-0">Email: <Link to={`mail:${generalInfo.email}`} className="text-muted mb-0" >{generalInfo.email}</Link></p>
            </div>
            <div className="col">
              <div className="feature bg-warning bg-gradient text-white rounded-pill mb-3"><i className="icofont-whatsapp"></i></div>
              <div className="h5 fw-bold text-black">Order on WhatsApp</div>
              <Link to={`https://wa.me/${generalInfo.mobile}?text=${encodeURIComponent(generalInfo.message)}`} target="_blank" className="text-muted mb-0">{generalInfo.mobile}</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="pe-lg-5">
                <div className="pe-lg-5">
                  <div className="py-5">
                    <div className="display-5 text-dark fw-bold mb-2">Get In Touch.</div>
                    <p className="pt-1 pb-3 lead">Fill in the form to the right to get in touch with someone on our team, and we will reach out shortly.</p>
                    <ContactForm/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
            <IframeResizer
                license="xxxx"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.074238065302!2d76.75986607584119!3d19.279137545562712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd019d6902c2a47%3A0x744080aea14f497d!2sIsmail%20Umar%20%26%20Co.!5e0!3m2!1sen!2sin!4v1723944124521!5m2!1sen!2sin"
                style={{ width: '100%', height: '700px' }}
                waitForLoad
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default Contact
