import React from 'react';
import { Carousel } from 'react-bootstrap';
import { partnerLogos } from '../../utils/config';

const Partners = () => {
  return (
    <section className="py-5">
      <div className="container my-5">
        <div className="text-center">
          <h2 className="fw-bolder">Our Partners</h2>
          <p className="lead fw-normal text-muted mb-5">
            Our best journey with valuable partners from the last decade.
          </p>
        </div>
        <div className="row gx-5 align-items-center">
          <Carousel indicators={false} interval={3000}>
            {partnerLogos.map((partner) => (
              <Carousel.Item key={partner.id}>
                <img
                  className="d-block w-100"
                  src={partner.logo}
                  alt={partner.name}
                  style={{ height: '200px', objectFit: 'contain' }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Partners;
