import { Link, useLocation } from "react-router-dom";
import { brandName, menu } from "../../utils/config";
import { useEffect, useState } from "react";

const Nav = () => {
  const [toggle, setToggle] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setToggle(false);
  }, [location]);
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top shadow-sm osahan-header py-0">
        <div className="container">
          <Link to="/" className="navbar-brand me-0 me-lg-3 me-md-3" >
            {/* <img src={logo} alt="#" className="img-fluid d-none d-md-block" style={{height: "70px"}}  />
            <img src="assets/img/fav.png" alt="#" className="d-block d-md-none d-lg-none img-fluid" /> */}
            <h4 style={{ color: "#ffcc00" }}>{brandName}</h4>
          </Link>
          {/* <a href="#" className="ms-3 text-left d-flex text-dark align-items-center gap-2 text-decoration-none bg-white border-0 me-auto" data-bs-toggle="modal" data-bs-target="#add-delivery-location">
            <i className="bi bi-geo-alt-fill fs-5 text-success"></i>
            <span>
              <b>Delivery in 15 minutes</b>
              <div className="small text-success">Sant Pura, Industrial Area...<i className="bi bi-arrow-right-circle-fill ms-1"></i></div>
            </span>
          </a> */}
          <button className="navbar-toggler collapsed" onClick={() => setToggle(!toggle)} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${toggle ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto me-3 top-link">
              {
                menu.map(item => (
                  <li className="nav-item" key={item.title}>
                    <Link className="nav-link" to={item.url} role="button">{item.title}</Link>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
};

export default Nav
