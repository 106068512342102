import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const form = useRef();
  const [isProcessing, setIsProcessing] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const validateMobile = (mobile) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobile);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const email = form.current.user_email.value;
    const mobile = form.current.user_mobile.value;

    if (!validateEmail(email)) {
      setIsProcessing(false);
      Swal.fire('Invalid Email!', 'Please enter a valid email address.', 'error');
      return;
    }

    if (!validateMobile(mobile)) {
      setIsProcessing(false);
      Swal.fire('Invalid Mobile!', 'Please enter a valid 10-digit Indian mobile number.', 'error');
      return;
    }

    emailjs
      .sendForm('service_5u0oywp', 'template_nf4xdls', form.current, {
        publicKey: '_I1GD2cwPWqtmY1dY',
      })
      .then(
        () => {
          Swal.fire('Success!', 'Your message has been sent successfully.', 'success');
          form.current.reset();
          setIsProcessing(false);
        },
        (error) => {
          Swal.fire('Failed!', 'Something went wrong. Please try again later.', 'error');
          setIsProcessing(false);
        },
      );
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <div className="d-flex gap-3 justify-content-between mb-3">
          <div className="w-100">
            <input type="text" className="form-control" placeholder="Name" name="user_name" required />
          </div>
        </div>
        <div className="d-flex gap-3 justify-content-between mb-3">
          <div className="w-100">
            <input type="text" className="form-control" placeholder="Email" name="user_email" required />
          </div>
          <div className="w-100">
            <input type="text" className="form-control" placeholder="Mobile" name="user_mobile" required />
          </div>
        </div>
        <div className="d-flex gap-3 justify-content-between mb-3">
          <div className="w-100">
            <input type="text" className="form-control" placeholder="Address" name="user_address" required />
          </div>
        </div>
        <div className="my-3">
          <textarea className="form-control" placeholder="Message" name="user_message" rows="6"></textarea>
        </div>
        <button type="submit" className="btn btn-success btn-lg px-4 text-uppercase" disabled={isProcessing}>
          {isProcessing ? 'Processing...' : 'Submit'}
        </button>
      </form>
    </>
  );
};

export default ContactForm;

