import { Route, Routes } from "react-router-dom";
import Nav from "./components/common/Nav";
import About from "./pages/About";
import Footer from "./components/common/Footer";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  return (
    <>
      <Nav />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
