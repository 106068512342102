import { foodrite, kpl, mealtime, team1, team2, vinal } from "./Images";

export const brandName = 'Ismail Umar And Company';
export const menu = [
  { title: "Home", url: "/" },
  { title: "Contact Us", url: "/contact-us" }
]

export const getInTouch = [
  { title: "contact@iucmart.com", url: "contact@iucmart.com", type: "mail" },
  { title: "+91 97651 51786", url: "919765151786", type: "phone" },
  { title: "Facebook", url: "https://www.facebook.com/iucmart1517", type: "url" },
]

export const generalInfo = {
  email: "contact@iucmart.com",
  mobile: 919765151786,
  address: 'OLD MONDHA, OLD MONDHA, PARBHANI, Parbhani, Maharashtra, 431401',
  message: 'Hello, Ismail Umar & Company my order is as follows'
}

export const team = [
  { name: "Idris Boghani", designation: "Proprietor - Ismail Umar And Company", email: "idris@iucmart.com", mobile: 919765151786, image: team1 },
  { name: "Irshad Boghani", designation: "Proprietor - Ismail Umar Sales", email: "contact@iucmart.com", mobile: 917030807030, image: team2 },
]

export const partnerLogos = [
  { id: 1, name: 'Foodrite', logo: foodrite },
  { id: 2, name: 'MealTime', logo: mealtime },
  { id: 3, name: 'KPL', logo: kpl },
  { id: 4, name: 'Vinal', logo: vinal },
];